<template>
  <div>
    <noticia-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <modal-multimedia
      ref="refModalMultimedia"
      :data-info="dataInfo"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar noticia..."
              />
              <v-select
                v-model="seccionSel"
                :reduce="m => m.value"
                label="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="secciones"
                :clearable="false"
                class="invoice-filter-select mr-1"
                placeholder="Seleccione Tipo"
              >
                <template #selected-option="{ text }">
                  <span class="text-truncate overflow-hidden">
                    {{ text }}
                  </span>
                </template>
              </v-select>
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(titulo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                v-if="data.item.multimedia.length > 0"
                :src="`${pathMultimedia}/byUrl?isthumb=true&url=${data.item.multimedia[0].url}`"
                width="100"
                rounded
                alt="Banner Noticia"
                class="d-inline-block"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.titulo }}
            </span>
            <small class="text-muted d-block ">{{ data.item.fecha }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-button-toolbar justify>
            <b-button-group size="sm">
              <b-button
                v-b-tooltip.hover.top="'Editar'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                @click="getEdit(data)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Imágenes'"
                variant="warning"
                class="btn-wishlist remove-wishlist"
                @click="getImages(data)"
              >
                <feather-icon icon="ImageIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Eliminar'"
                variant="danger"
                class="btn-cart move-cart"
                @click="getDelete(data)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BMedia, BSpinner, BImg, BButtonToolbar, BButtonGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import { showToast, showError } from '@/helpers'
import config from '@/services/config'
import NoticiaForm from './NoticiaForm.vue'
import ModalMultimedia from '../../../components/multimedia/ModalMultimedia.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BImg,
    BButtonToolbar,
    BButtonGroup,
    ModalMultimedia,

    vSelect,
    BSpinner,
    NoticiaForm,
  },
  data: () => ({
    isBusy: false,
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    seccion: '',
    items: [],
    fields: [
      { key: 'idNoticia', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'titulo', sortable: true },
      { key: 'acciones', tdClass: ['w100', 'text-center'], thClass: 'text-center' },
    ],
    dataInfo: {},
    pathMultimedia: `${config.pathRepo}${config.contextBO}/api/v1/multimedia`,
    secciones: [
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Publicadas',
        value: true,
      },
      {
        text: 'Borrador',
        value: false,
      },
    ],
    seccionSel: '',
  }),
  watch: {
    perPage: {
      handler() {
        this.listarRegistros()
      },
    },
    page: {
      handler() {
        this.listarRegistros()
      },
    },
    query: {
      handler() {
        this.listarRegistros()
      },
    },
    seccionSel: {
      handler() {
        this.listarRegistros()
      },
    },
  },
  async mounted() {
    await this.listarRegistros()
  },
  methods: {
    avatarText,
    async listarRegistros() {
      this.isBusy = true
      await store
        .dispatch('institucional/NOTICIA_FIND_ALL', {
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idNoticia%7CDESC',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    getImages(data) {
      this.dataInfo.alertMsgTitle = 'Imágenes registradas para la noticia.'
      this.dataInfo.alertMsgSubTitle = 'Dimensiones recomendadas 1000x667.'
      this.dataInfo.alertIcon = 'InfoIcon'
      this.$refs.refModalMultimedia.idSeccion = data.item.idNoticia
      this.$refs.refModalMultimedia.idDestinoTuristico = 0
      this.$refs.refModalMultimedia.seccion = 'NOTICIA'
      this.$refs.refModalMultimedia.orientacion = 'H'
      this.$refs.refModalMultimedia.loadRegisters()
      this.$refs.refModalMultimedia.openModal()
    },
    openForm(type) {
      this.formType = type
      this.isToggleSidebar = true
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data.item
    },
    getDelete(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('institucional/NOTICIA_DELETE', {
              id: data.item.idNoticia,
            })
            .then(response => {
              this.listarRegistros()
              this.$bvToast.toast('Ha Eliminado correctamente el registro.', {
                title: 'Operación Exitosa',
                variant: 'success',
                solid: true,
              })
              return response
            })
            .catch(error => {
              this.$bvToast.toast(error, {
                title: 'Ha ocurrido un error',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    getColorAvatar() {
      const max = 7
      const min = 1
      const status = Math.floor(Math.random() * ((max + 1) - min) + min)
      if (status === 1) return 'primary'
      if (status === 2) return 'danger'
      if (status === 3) return 'secondary'
      if (status === 4) return 'warning'
      if (status === 5) return 'info'
      if (status === 6) return 'success'
      return 'primary'
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listarRegistros()
    },
    getDateFmt(date) {
      return moment(String(date)).format('DD/MM/YYYY')
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.w100{
  width: 100px !important;
}
.badge{
  font-size: 75% !important;
}
</style>
